import PocketBase from "pocketbase";

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  const pocketbase = new PocketBase(config.public.pocketBaseDomain);

  return {
    provide: { pocketbase },
  };
});
