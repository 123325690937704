import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_jmBwdqbgTJ from "/app/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import pwa_icons_Y6agwlnXZq from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_YksOOA8Nkt from "/app/node_modules/nuxt-snackbar/dist/runtime/plugin.js";
import konva_client_XiAbRA0ZHu from "/app/plugins/konva.client.ts";
import pocketbase_client_4iHgIqZEhp from "/app/plugins/pocketbase.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import vue_query_wrmMkNpEpe from "/app/plugins/vue-query.ts";
import vue3_colorpicker_client_alnHKRJFJ5 from "/app/plugins/vue3-colorpicker.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  motion_jmBwdqbgTJ,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  plugin_YksOOA8Nkt,
  konva_client_XiAbRA0ZHu,
  pocketbase_client_4iHgIqZEhp,
  sentry_client_shVUlIjFLk,
  vue_query_wrmMkNpEpe,
  vue3_colorpicker_client_alnHKRJFJ5
]